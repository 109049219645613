import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import StagingPoints from '../components/OtherComponents/StagingPoints';
// import CompanyLogin from '../components/LoginScreenComponents/CompanyLogin';

const EarningPoints: FC = () => {
  const { staging_points, attentions, register_outs } = useSelector((state: RootState) => state.ranking);
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ paddingY: '10vh' }}>
          <StagingPoints
            points={staging_points ? staging_points : 0}
            attentions={attentions ? attentions : 0}
            register_outs={register_outs ? register_outs : 0}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EarningPoints;
