import React, { FC } from 'react';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import languages from '../../language/languages';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface Props {
  attentions: number;
  register_outs: number;
  points: number;
}

const StagingPoints: FC<Props> = (props: Props) => {
  const { selected } = useSelector((state: RootState) => state.language);
  const navigate = useNavigate();
  const ranking = () => {
    navigate('/ranking');
  };
  return (
    <Fade in={true}>
      <Box
        onClick={ranking}
        sx={{
          borderRadius: '20px',
          backgroundImage:
            'linear-gradient(to right,rgba(180,58,109,1) 0%, rgba(253,29,29,1) 20%, rgba(252,176,69,1) 100%)',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          margin: { xs: '0 5vw 5vw 5vw', sm: '0 5% 5% 5%' },
          zIndex: '4',
          position: 'relative',
          top: '0',
        }}>
        <Box sx={{ marginX: '2vw', marginY: '0', paddingY: '0' }}>
          <h3 className="App-profile-text">
            Llevas {props.attentions} atenciones esta semana, con {props.register_outs} registros completos. Esto
            significa que llevas {props.points} puntos esta semana. Sigue asi!
          </h3>
        </Box>
      </Box>
    </Fade>
  );
};

export default StagingPoints;
